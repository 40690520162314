/* Colors variables */
$background-color: #ecf7fe;
$main-color: #393939;
$green-color: #128577;
$red-color: #bf1b1b;
$blue-color: #1463a1;
$date-color: #5f5e6d;

/* sizes variables */
$tablet-size: 700px;
$desktop-size: 1024px;
$large-size: 1440px;
