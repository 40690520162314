@use "variables";

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-size: 2rem;
    text-align: center;
  }

  img {
    width: 4rem;
    margin: 0;
    padding-top: 37px;
  }
}

/* desktop and tablet styling */
@media screen and (min-width: variables.$tablet-size) {
  .navbar {
    img {
      width: 4rem;
      margin: 0;
      padding-top: 37px;
    }
  }
}

@media screen and (min-width: variables.$desktop-size) {
  .navbar {
    img {
      width: 4rem;
      margin: 0;
      padding-top: 37px;
    }
  }
}

@media screen and (min-width: variables.$large-size) {
  .navbar {
    img {
      width: 4rem;
      margin: 0;
      padding-top: 37px;
    }
  }

}
