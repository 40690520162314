.search {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;

  input {
    background: #ffffff;
    border-radius: 22px;
    border: none;
    width: 90%;
    padding: 0.5rem;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    outline: none;
  }

  img {
    margin-left: -2rem;
  }
}
