/* use */
@use "variables";

/* imports */
@import "account";
@import "balance";
@import "navbar";
@import "search";
@import "login";
@import "transactions";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

/* global styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body > #root > div {
  height: 100% !important;
}

body {
  background-color: variables.$background-color;
  color: variables.$main-color;

  margin: 0 auto;
  position: relative;
  padding-bottom: 4rem;
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
  color: variables.$date-color;
  margin-bottom: 0.5rem;
}

/* fonts */

/* mobile styles */
.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .auth-wrapper {
    width: 100%;
  }
}

.copyright {
  position: absolute;
  bottom: 0;
}

/* desktop and tablet styling */
@media screen and (min-width: variables.$desktop-size) {
}

@media screen and (min-width: variables.$large-size) {
  .App {
    width: 800px;
    margin: 0 auto;
  }
}
