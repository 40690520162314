@use "variables";

.transactions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 55vh;

  a {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
    color: variables.$blue-color;
    align-self: flex-end;
    cursor: pointer;
    margin-bottom: 1rem;
  }

  .transactionsSection {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.transactionByDate {
  width: 100%;
  margin-bottom: 1.5rem;

  p {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
    color: variables.$date-color;
    margin-bottom: 0.5rem;
  }

  .transactionsBloc {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 0.5rem 0.5rem 0.2rem 0.5rem;
  }
}

.transaction {
  width: 95%;
  display: flex;
  align-items: center;
  padding-bottom: 0.3rem;
  margin-bottom: 1rem;
  margin: 0.5rem auto;
  border-bottom: 1px solid rgba(103, 103, 103, 0.21);

  h3 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    color: variables.$main-color;
    align-self: center;
  }

  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 0.6rem;
  }

  hr {
    width: 80%;
  }

  img {
    width: 2.5rem;
    margin-right: 1rem;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .amount {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: medium;
  }

  .positiveAmount {
    color: variables.$green-color;
  }

  .negativeAmount {
    color: variables.$red-color;
  }

  .transactionDetails {
    flex: 2;

    h3 {
      font-size: 1rem;
      float: left;
    }
  }
}

.allTransactions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}



.content {
  display: flex;
  flex-flow: row wrap;
}

.small-text {
  font-size: smaller;
}

.dashboard-title {
  margin-top: 50px;
  margin-bottom: 50px;
}

@media screen and (min-width: variables.$desktop-size) {

  .elem {
    width: 250px;
    height: auto;
  }
}

@media screen and (max-width: variables.$tablet-size) {

  .elem {
    width: 150px;
    height: auto;
  }
}