.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper .login {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;

}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

/* desktop and tablet styling */
@media screen and (min-width: variables.$desktop-size) {

  .auth-wrapper .login {
    margin-top: 100px !important;
    width: 480px;
  }
}

@media screen and (max-width: variables.$tablet-size) {

  .auth-wrapper .login {
    margin-top: 50px !important;
  }

  .App {
    width: 80%;
    margin: 0 auto;
  }
}


.wrapper {
  position:relative;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  box-sizing:border-box;
  top: 54%;
  left: 4px;
  transform: translateY(-50%);
}


.input {
  height: 50px;
  box-sizing:border-box;
  padding-left: 1.5rem;
}