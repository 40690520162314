@use "variables";

.account {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-weight: 300;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    padding: 1rem;
  }

  span {
    font-weight: 500;
    font-size: 1.2rem;
    color: variables.$blue-color;
  }
}


.dashboard-title{
  margin-top: 50px;
  margin-bottom: 50px;
}


.balance {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  margin-bottom: 2rem;

  h3 {
    font-weight: 300;
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
  }

  h2 {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}


.positiveAmount {
  color: variables.$green-color;
}

.negativeAmount {
  color: variables.$red-color;
}

/* desktop and tablet styling */
@media screen and (min-width: variables.$tablet-size) {
  .balance {
    width: 50%;
  }
}

@media screen and (min-width: variables.$desktop-size) {
  .balance {
    width: 30%;
  }
}

@media screen and (min-width: variables.$large-size) {
  .balance {
    width: 30%;
  }
}
